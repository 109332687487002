import React, { useState, useCallback, useEffect } from 'react';
import { ConditionMatcher } from '../../condition-builder/condition-matcher';
import { useConfigurationLoader } from './helpers/useConfigLoader';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';

export const useForm = ({
  config,
  onFieldChange,
  hiddenFields,
  defaultValues = {},
  onConfigChange,
}) => {
  const initialFieldsConfig = config?.fields || [];
  const dynamicFields = config?.dynamicFields || [];
  const { getConfig } = useConfigurationLoader();
  const [fieldsConfig, setFieldsConfig] = useState(initialFieldsConfig);
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState({});
  const conditionMatcher = new ConditionMatcher();
  const { t } = useAureliaI18n();
  const validate = (vs, fields) => {
    const errors = {};
    fields.forEach((field) => {
      const isShow = shouldShowField(field);
      if (
        field.required &&
        isShow &&
        (field.type === 'checkbox'
          ? vs[field.property] === undefined
          : !vs[field.property])
      ) {
        errors[field.property] = `${t(
          field?.label || field?.checkboxLabel
        )} is required`;
      }
    });
    return errors;
  };

  useEffect(() => {
    const initialValues = fieldsConfig.reduce((acc, field) => {
      acc[field.property] = defaultValues[field.property];
      null;
      return acc;
    }, {});
    setValues(initialValues);
  }, []);

  useEffect(() => {
    const initialValues = fieldsConfig.reduce((acc, field) => {
      acc[field.property] = values[field.property] || field?.default || null;
      return acc;
    }, {});
    setValues(initialValues);
  }, [fieldsConfig]);

  const handleChange = useCallback(
    async (property, value) => {
      setValues((prevValues) => ({ ...prevValues, [property]: value }));
      setLoading((prev) => ({ ...prev, [property]: true }));
      try {
        const newValues = { ...values, [property]: value };
        if (onFieldChange) {
          const updatedConfig = await onFieldChange({
            property,
            value,
            newValues,
            setValues,
          });
          if (updatedConfig) {
            setFieldsConfig(updatedConfig);
          }
        }

        if (dynamicFields.includes(property)) {
          const notNullValues = Object.fromEntries(
            Object.entries(newValues).filter(([key, value]) => {
              return value;
            })
          );
          const data = await getConfig(
            `${config?.moduleId}/${config?.id}`,
            notNullValues
          );
          setFieldsConfig(data?.fields);
          if (onConfigChange) {
            onConfigChange(data);
          }
        }
      } catch (error) {
        console.error('Error updating field:', error);
      } finally {
        setLoading((prev) => ({ ...prev, [property]: false }));
      }
    },
    [values, onFieldChange]
  );

  const handleBlur = useCallback(
    (property) => {
      return true;
      const validationErrors = validate({
        ...values,
        [property]: values[property],
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [property]: validationErrors[property],
      }));
    },
    [values, validate]
  );

  const valuesParser = (values, config) => {
    const newValues = Object.fromEntries(
      Object.entries(values)
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => {
          const field = config.find((f) => f.property === key);

          if (field?.type === 'choice' && field?.multiple) {
            value = value?.map((v) => v?.value?.id || v?.value || v) || [];
          }

          return [key, value];
        })
    );

    return newValues;
  };

  const handleSubmit = useCallback(
    (onSubmit) => async (event) => {
      event?.preventDefault();
      setIsSubmitting(true);

      const validationErrors = validate(values, fieldsConfig);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        const parsedValues = valuesParser(values, fieldsConfig);
        await onSubmit(parsedValues);
      }

      setIsSubmitting(false);
    },
    [values, validate]
  );

  const shouldShowField = useCallback(
    (field) => {
      if (!field || field.hidden) {
        return false;
      }
      if (hiddenFields.includes(field.property)) {
        return false;
      }

      if (!field.showIf || field.showIf.length === 0) {
        return true;
      }
      return conditionMatcher.matchConditions(values, field.showIf);
    },
    [values, hiddenFields]
  );

  return {
    fieldsConfig,
    values,
    setValues,
    errors,
    isSubmitting,
    loading,
    handleChange,
    handleBlur,
    handleSubmit,
    shouldShowField,
  };
};
