import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { WarningAmber, ExpandMore, AutoFixHigh } from '@mui/icons-material';
import { useUserClient } from '../../../react-hooks/useUserClient';
import { useClient } from '../../../react-hooks/useClient';
import { ArtificialIntelligence } from '../../../artificial-intelligence/helper/artificial-intelligence';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import debounce from 'debounce-promise';

export const TranslatableField = ({
  field,
  value = {},
  onChange,
  margin,
  error,
}) => {
  const [userClient] = useUserClient();
  const client = useClient();
  const [currentValue, setCurrentValue] = useState('');
  const [autoTranslationActive, setAutoTranslationActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasTranslation, setHasTranslation] = useState(false);
  const [
    isArtificialIntelligenceEnabled,
    setIsArtificialIntelligenceEnabled,
  ] = useState(false);
  const { t, locale } = useAureliaI18n();
  const locales = locale['allLocales'] || ['de'];
  const [activeLocale, setActiveLocale] = useState(
    locale['contentLocale'] || 'de'
  );

  useEffect(() => {
    const user = userClient.getUser();
    setAutoTranslationActive(
      !user.settings.userSettings.disableAutomaticTranslation
    );
    setHasTranslation(
      user.instance?.settings?.translationInstanceSettings?.hasTranslation
    );
    setIsArtificialIntelligenceEnabled(
      user?.instance?.settings?.artificialIntelligenceInstanceSettings?.isActive
    );

    const initialValue = {};
    locales.forEach((locale) => {
      initialValue[locale] = value[locale] || '';
    });
    onChange(initialValue);
  }, []);

  useEffect(() => {
    if (value) {
      setCurrentValue(value[activeLocale] || '');
    }
  }, [activeLocale, value]);

  const handleLocaleChange = (locale) => {
    setActiveLocale(locale);
  };

  const autoTranslation = async (newValue) => {
    if (!newValue || !hasTranslation) return;

    setIsLoading(true);
    const remainingLanguages = locales.filter((lang) => lang !== activeLocale);
    const translations = await Promise.all(
      remainingLanguages.map((lang) => translate(activeLocale, lang, newValue))
    );

    const newValues = { [activeLocale]: newValue };
    remainingLanguages.forEach((lang, index) => {
      newValues[lang] = translations[index];
    });
    onChange(newValues);
    setIsLoading(false);
  };

  const debouncedAutoTranslation = useCallback(
    debounce((newValue) => {
      return autoTranslation(newValue);
    }, 500),
    [
      hasTranslation,
      autoTranslationActive,
      field?.subType,
      locales,
      activeLocale,
    ]
  );
  const handleValueChange = (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    onChange({ ...value, [activeLocale]: newValue });

    if (
      hasTranslation &&
      autoTranslationActive &&
      field.subType !== 'htmlarea'
    ) {
      debouncedAutoTranslation(newValue);
    }
  };

  const translate = async (from, to, text) => {
    try {
      const response = await client.post('translate', {
        localeFrom: from,
        localeTo: to,
        text,
      });
      return response.data;
    } catch (error) {
      console.error('Translation error:', error);
      return '';
    }
  };

  const handleTranslateFrom = async (locale) => {
    setIsLoading(true);
    const text = value[locale] || '';
    const translation = await translate(locale, activeLocale, text);
    onChange({ ...value, [activeLocale]: translation });
    setCurrentValue(translation);
    setIsLoading(false);
    setAnchorEl(null);
  };

  const openArtificialIntelligenceDialog = async () => {
    try {
      const { id, modelId } = field?.parent?.contextObjectRef || {};
      //   const res = await artificialIntelligence.generate({ id, modelId });
      //   if (!artificialIntelligence.validateResponseObj(res)) {
      //     onChange({ ...value, [activeLocale]: res.response });
      //     setCurrentValue(res.response);
      //   }
    } catch (error) {
      console.error('AI generation error:', error);
    }
  };

  return (
    <div>
      <div>
        {locales.length > 1 && (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <ButtonGroup variant="outlined" size="small">
                {locales.map((locale) => (
                  <Button
                    key={locale}
                    onClick={() => handleLocaleChange(locale)}
                    variant={locale === activeLocale ? 'contained' : 'outlined'}
                  >
                    {locale}
                    {value && !value[locale] && (
                      <WarningAmber fontSize="small" color="warning" />
                    )}
                  </Button>
                ))}
                <Button
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  endIcon={<ExpandMore />}
                  disabled={
                    autoTranslationActive && field.subType !== 'htmlarea'
                  }
                >
                  Übersetzen von
                </Button>
              </ButtonGroup>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {locales
                  .filter((locale) => locale !== activeLocale)
                  .map((locale) => (
                    <MenuItem
                      key={locale}
                      onClick={() => handleTranslateFrom(locale)}
                    >
                      {locale.toUpperCase()}
                    </MenuItem>
                  ))}
              </Menu>
            </div>
            <div>
              {hasTranslation &&
                field.subType !== 'htmlarea' &&
                locales.length > 1 && (
                  <FormControlLabel
                    sx={{ marginBottom: '0px' }}
                    control={
                      <Checkbox
                        checked={autoTranslationActive}
                        onChange={() =>
                          setAutoTranslationActive(!autoTranslationActive)
                        }
                      />
                    }
                    label="Automatische Übersetzung"
                  />
                )}
              {field.subType === 'textarea' && isArtificialIntelligenceEnabled && (
                <Button
                  onClick={openArtificialIntelligenceDialog}
                  startIcon={<AutoFixHigh />}
                  style={{ marginTop: 10 }}
                >
                  AI Generation
                </Button>
              )}
            </div>
          </div>
        )}
        <div>
          {field.subType === 'textarea' || field.subType === 'htmlarea' ? (
            <TextField
              multiline
              rows={4}
              fullWidth
              value={currentValue}
              onChange={handleValueChange}
              required={field?.required}
            />
          ) : (
            <TextField
              fullWidth
              value={currentValue}
              onChange={handleValueChange}
              name={t(field.label)}
              label={t(field.label)}
              margin={margin}
              error={!!error}
              required={field?.required}
            />
          )}
        </div>
      </div>

      {isLoading && <CircularProgress size={24} />}
    </div>
  );
};

export default TranslatableField;
