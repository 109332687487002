import React, {
  useImperativeHandle,
  forwardRef,
  useMemo,
  useEffect,
} from 'react';
import { useForm } from './useForm';
import { Button, Grid, CircularProgress, TextField } from '@mui/material';
import { useAureliaI18n } from '../../react-hooks/useAureliaI18n';
import FormField from './helpers/FormField';
import { defaultFieldRenderers } from './helpers/defaultFieldRenderers';

const DynamicForm = forwardRef(
  (
    {
      config,
      onSubmit,
      onFieldChange,
      hideSubmit,
      overideRenderers = {},
      hiddenFields = [],
      defaultValues = {},
      onConfigChange
    },
    ref
  ) => {
    const {
      fieldsConfig,
      values,
      errors,
      isSubmitting,
      loading,
      handleChange,
      handleBlur,
      handleSubmit,
      shouldShowField,
      setValues,
    } = useForm({ config, onFieldChange, hiddenFields, defaultValues, onConfigChange });

    useImperativeHandle(ref, () => ({
      submit: () => handleSubmit(onSubmit)(),
      setDynamicFormValues: setValues,
    }));

    const Renderers = useMemo(
      () => ({ ...defaultFieldRenderers, ...overideRenderers }),
      []
    );

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Grid container spacing={2}>
          {fieldsConfig.map(
            (field) =>
              shouldShowField(field) && (
                <Grid
                  item
                  key={field.property}
                  xs={12}
                  md={field?.column?.includes('col-md-6') ? 6 : 12}
                >
                  {Renderers[field?.type] ? (
                    <FormField
                      margin={'dense'}
                      field={field}
                      value={values[field.property]}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      error={errors[field.property]}
                      loading={loading[field.property]}
                      overideRenderers={overideRenderers}
                      Renderer={Renderers[field?.type]}
                    />
                  ) : (
                    <div>renderer dont exist</div>
                  )}
                  {loading[field.property] && <CircularProgress size={20} />}
                </Grid>
              )
          )}
          {!hideSubmit && (
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || Object.values(loading).some(Boolean)}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
);

export default DynamicForm;
