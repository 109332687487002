import React, { useCallback, useState, useEffect, useMemo } from 'react';
import {
  TextField,
  Autocomplete as AC,
  Checkbox,
  Button,
  Box,
} from '@mui/material';
import useChoice from '../../../react-hooks/useChoice';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';
import { useClient } from '../../../react-hooks/useClient';
import CloseIcon from '@mui/icons-material/Close';

const Autocomplete = React.memo(
  ({
    options = [],
    value,
    onChange,
    label,
    multiple,
    renderCheckbox,
    disableCloseOnSelect,
    field,
    error,
    size = 'medium',
    variant = 'outlined',
    showSelectAll = false,
  }) => {
    const { t } = useAureliaI18n();
    const client = useClient();
    const [searchChoices, setSearchChoices] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [input, setInput] = useState('');
    const [choices, setChoices] = useState([]);

    const isSearchable = field?.searchable && field?.options?.modelId;

    renderCheckbox = renderCheckbox || field?.multiple;
    disableCloseOnSelect =
      disableCloseOnSelect || field?.multiple ? true : false;

    const onInputValueChange = useCallback(async (_, inputValue) => {
      if (isSearchable) {
        setInput(inputValue);
        const newChoices = await client.get(
          `model/choice/${field?.options?.modelId}?conditions={%22search%22:%22${inputValue}%22}`
        );
        setSearchChoices(newChoices);
      }
    }, []);

    const hasChoicesList =
      field?.choices?.length > 0 || field?.choices?.[0]?.value;

    const fetchedChoices = useChoice({
      type: field?.options?.choice?.set || field?.set,
      getAll: true,
    });

    const modalChoices = useChoice({
      type: field?.modelId,
      isModel: true,
    });

    const processChoices = useMemo(
      () => (choicesList) => {
        return choicesList?.map((c) => ({
          value: c.value,
          label: t(c.label),
          help: c?.help,
        }));
      },
      [t]
    );

    useEffect(() => {
      let newChoices = [];
      if (hasChoicesList) {
        const choicesList = Array.isArray(field?.choices)
          ? field.choices
          : Object.values(field?.choices || {});
        newChoices = processChoices(choicesList);
      } else if (field?.options?.choice?.set || field?.set) {
        newChoices = fetchedChoices;
      } else if (field?.modelId) {
        newChoices = modalChoices;
      }
      setChoices(newChoices);
      setSelectedValues(getValue(value, newChoices));
    }, [
      hasChoicesList,
      field,
      fetchedChoices,
      modalChoices,
      processChoices,
      value,
    ]);

    const renderOptions = useCallback(
      (props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li
            key={`${key}-${option.value}-${option.help}-${option.label}`}
            {...optionProps}
          >
            {renderCheckbox && (
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{option.label}</div>
              {option?.help && (
                <div style={{ fontSize: '12px' }}>{option.help}</div>
              )}
            </div>
          </li>
        );
      },
      [renderCheckbox]
    );

    const handleSelectionChange = (event, newValue) => {
      const updatedValue = Array.isArray(newValue) ? newValue : newValue?.value;
      onChange(updatedValue);
      setSelectedValues(updatedValue);
    };

    const AcOptions =
      options?.length > 0 ? options : Array.isArray(choices) ? choices : [];

    const getLabelValue = (v) => {
      if (!AcOptions.length) return '';
      if (typeof v === 'string') {
        const option = AcOptions?.find((o) => o?.value == v || o == v);
        return option?.label || '';
      }
      return '';
    };

    const getValue = (v, availableOptions) => {
      if (!v) return [];
      const options = isSearchable ? searchChoices : availableOptions;

      if (field?.multiple || multiple) {
        if (
          Array.isArray(v) &&
          v?.length > 0 &&
          Array.isArray(options) &&
          options.length > 0
        ) {
          return v.map(
            (item) =>
              options?.find(
                (o) => o?.value?.id == item || o?.value == item || o == item
              ) || item
          );
        }
      }

      if (typeof v === 'string' && Array.isArray(options)) {
        return (
          options?.find((o) => o?.value?.id == v || o?.value == v || o == v) ||
          v
        );
      }

      return Array.isArray(v) ? v : [v];
    };

    const choicesList = input ? searchChoices : AcOptions;

    const filterOptions = (options, { inputValue }) => {
      const filterValue = inputValue.toLowerCase().trim();
      return choicesList.filter(
        (option) =>
          option.label.toLowerCase().includes(filterValue) ||
          String(option.value).toLowerCase().includes(filterValue)
      );
    };

    const handleSelectAll = () => {
      handleSelectionChange('', [...choicesList]);
    };

    return (
      <>
        <AC
          size={size}
          noOptionsText={t("muiTable.noOptions")}
          options={choicesList}
          multiple={field?.multiple || multiple}
          value={selectedValues || []}
          onChange={handleSelectionChange}
          disableCloseOnSelect={disableCloseOnSelect || field?.multiple}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label || t(field.label)}
              name={label || t(field.label)}
              error={!!error}
              variant={variant}
              required={field?.required}
              fullWidth
            />
          )}
          renderOption={renderOptions}
          isOptionEqualToValue={(option, value) => {
            if (value === null || value === undefined) return false;
            if (typeof value === 'string' || typeof value === 'number') {
              return option.value === value;
            }

            if (option?.value?.id && value?.value?.id) {
              return option.value.id == value.value.id;
            }
            return option.value === value.value;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return getLabelValue(option);
            }
            return option?.label || '';
          }}
          disablePortal={false}
          filterOptions={filterOptions}
          clearIcon={<CloseIcon onClick={() => setInput('')} />}
          {...(isSearchable
            ? { inputValue: input, onInputChange: onInputValueChange }
            : {})}
        />
        {showSelectAll &&
          (field?.multiple || multiple) &&
          choicesList?.length > 0 && (
            <a
              onClick={handleSelectAll}
              style={{
                cursor: 'pointer',
                textAlign: 'right',
                width: '100%',
                display: 'block',
                marginTop: '6px',
                marginBottom: '6px',
              }}
            >
              Alle auswählen
            </a>
          )}
      </>
    );
  }
);

export default Autocomplete;
