import React from 'react';
import { Checkbox as MUICheckbox, FormControlLabel } from '@mui/material';

const Checkbox = React.memo(({ value, onChange, field }) => {
  const isChecked = !!value;
  const handleChange = (e) => {
    if (onChange) {
      onChange(e.target.checked);
    }
  };
  return (
    <FormControlLabel
      control={
        <MUICheckbox
          checked={isChecked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          required={field?.required}
        />
      }
      label={field?.checkboxLabel}
    />
  );
});

export default Checkbox;
