import React from 'react';
import { TextField as TextMUI } from '@mui/material';
import { useAureliaI18n } from '../../../react-hooks/useAureliaI18n';

const TextField = ({ field, value, onChange, margin, error }) => {
  const { t } = useAureliaI18n();
  const handleFieldChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <TextMUI
      name={t(field.label)}
      label={t(field.label)}
      margin={margin}
      onChange={handleFieldChange}
      value={value}
      error={!!error}
      fullWidth
      required={field?.required}
    />
  );
};

export default TextField;
