import React from 'react';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker as DateRangePickerField } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { de } from 'date-fns/locale';
import moment from 'moment';
import {
  SingleInputDateRangeField,
  MultiInputDateRangeField,
} from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);
export const Default_Date_Format = 'YYYY-MM-DDTHH:mm:ssZ';
export const dateShortcutsItems = [
  {
    label: 'Heute',
    getValue: () => {
      const today = dayjs();
      return [today.toDate(), today.toDate()];
    },
  },

  {
    label: 'Diese Jahr',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('year').toDate(), today.endOf('year').toDate()];
    },
  },

  {
    label: 'Diese Woche',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week').toDate(), today.endOf('week').toDate()];
    },
  },
  {
    label: 'Letzte Woche',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [
        prevWeek.startOf('week').toDate(),
        prevWeek.endOf('week').toDate(),
      ];
    },
  },

  {
    label: 'Dieser Monat',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month').toDate(), today.endOf('month').toDate()];
    },
  },
  {
    label: 'Letzte Monat',
    getValue: () => {
      const today = dayjs();
      const preMonth = today.subtract(1, 'month');

      return [
        preMonth.startOf('month').toDate(),
        preMonth.endOf('month').toDate(),
      ];
    },
  },
  {
    label: 'Letztes Jahr',
    getValue: () => {
      const today = dayjs();
      const preYear = today.subtract(1, 'year');
      return [preYear.startOf('year').toDate(), preYear.endOf('year').toDate()];
    },
  },
  {
    label: 'Letzte zwei Jahre',
    getValue: () => {
      const today = dayjs();
      const preYear1 = today.subtract(2, 'year');
      return [preYear1.toDate(), today.toDate()];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

export const isValidDate = (date) => {
  if (!date || date == 'undefined' || date == 'Invalid Date') {
    return false;
  }
  const momentDate = moment(date);
  return momentDate.isValid() && momentDate.year() >= 1970;
};

export const parseDateValue = (v) => {
  const [start, end] = v;
  return [dayjs(start).toDate(), dayjs(end).toDate()];
};

export const DateRangePicker = ({
  margin,
  value,
  onChange,
  field,
  error,
  asSingleField,
  renderAsComponent,
  textFieldProps = {},
}) => {
  // TODO: Default value

  const onDateChange = (value) => {
    const [from, to] = value;
    if (asSingleField) {
      if (isValidDate(from) && !isValidDate(to)) {
        const formattedFromDate = dayjs(from).format(Default_Date_Format);
        onChange([formattedFromDate, null]);
      }
      if (!isValidDate(from) && isValidDate(to)) {
        const formattedToDate = dayjs(to).format(Default_Date_Format);
        onChange([null, formattedToDate]);
      }
    }

    if (isValidDate(from) && isValidDate(to)) {
      const formattedFromDate = dayjs(from).format(Default_Date_Format);
      const formattedToDate = dayjs(to).format(Default_Date_Format);
      onChange([formattedFromDate, formattedToDate]);
    }
  };

  const slots = {
    field: renderAsComponent
      ? renderAsComponent
      : asSingleField
      ? SingleInputDateRangeField
      : undefined,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <DateRangePickerField
        defaultValue={null}
        name={field?.label}
        margin={margin}
        value={value?.length > 1 ? parseDateValue(value) : []}
        onChange={onDateChange}
        slotProps={{
          shortcuts: {
            items: dateShortcutsItems,
          },
          field: {
            format: 'dd.MM.yyyy',
          },
          textField: {
            error: !!error,
            ...(textFieldProps || {}),
          },
        }}
        localeText={{
          start: 'Von',
          end: 'Bis',
        }}
        {...(slots.field !== undefined && { slots })}
        required={field?.required}
      />
    </LocalizationProvider>
  );
};
