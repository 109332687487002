import { useState, useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useClient } from '../../../react-hooks/useClient';

export const useConfigurationLoader = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getConfig = useCallback(
    (config, data) => {
      setLoading(true);
      setError(null);

      const fetchConfig = async () => {
        try {
          if (typeof config === 'string') {
            const response = await client.post(
              'form/config/' + config,
              typeof data === 'string'
                ? { id: data }
                : typeof data === 'object'
                ? data
                : undefined
            );
            return _.cloneDeep(response.data);
          } else if (typeof config === 'object') {
            return _.cloneDeep(config);
          } else {
            throw new Error('Invalid configuration');
          }
        } catch (err) {
          setError(err);
          throw err;
        } finally {
          setLoading(false);
        }
      };

      return fetchConfig();
    },
    []
  );

  return {
    getConfig,
    loading,
    error,
  };
};
