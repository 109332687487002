import { DateRangePicker } from '../fields/DateRangePicker';
import Autocomplete from '../fields/Autocomplete';
import Checkbox from '../fields/Checkbox';
import TextField from '../fields/TextField';
import TranslatableField from '../fields/TranslateableField';

export const defaultFieldRenderers = {
    text: TextField,
    'date-range': DateRangePicker,
    choice: Autocomplete,
    checkbox: Checkbox,
    translatable: TranslatableField
};
