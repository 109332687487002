import React, { useCallback } from 'react';

const FormField = React.memo(
  ({
    field,
    value,
    handleChange,
    handleBlur,
    error,
    loading,
    Renderer,
    margin,
  }) => {

    const onChange = useCallback(
      (value) => handleChange(field.property, value),
      [value]
    );

    return (
      <Renderer
        name={field.property}
        value={value}
        onChange={onChange}
        fullWidth
        margin={margin}
        onBlur={handleBlur}
        error={error}
        loading={loading}
        field={field}
      />
    );
  }
);

export default FormField;
